import React from 'react'
import { icons } from '../../assets/icons'
import { images } from '../../assets/imgs'
import { useSelector } from 'react-redux'

export const TheTeam = () => {
    const { language } = useSelector(state => state.menu)
    return (
        <div className='w-full h-auto xl:h-screen min-h-auto xl:min-h-[970px]  bg-FF521D flex flex-col  xl:flex-row justify-center items-center'>
            <div className='h-full w-full xl:w-4/12 flex flex-col justify-center items-center xl:items-start pt-[90px] xl:pt-0 pb-6 xl:pb-0'>
                <img src={images.theTeam} className='hidden xl:block xl:h-[549px] 2xl:h-[600px] object-contain noselect' alt="" />
                <img src={language.theTeammg} className='block xl:hidden h-[340px] object-contain noselect' alt="" />
            </div>
            <div className='h-full w-8/12 flex justify-start md:justify-center xl:justify-start items-center  pb-[100px] xl:pb-0'>
                <div className='xl:h-[549px] 2xl:h-[600px] flex flex-col  xl:flex-row'>
                    <div className=''>
                        <img src={images.maga} className="w-[270px] xl:w-[230px] 2xl:w-[288px] xl:h-[230px] 2xl:h-[288px] rounded-full" alt="maga" />
                        <p className='DMSans-Medium text-white text-[40px] xl:text-[48px] 2xl:text-[56px] '>Maga</p>
                        <p className='DMSans-Medium text-white xl:text-[18px] 2xl:text-[21px]'>{language.maga.role}</p>
                        <div className='flex items-center gap-2'>
                            <img src={icons.starTeam} className="w-[18px] mt-1" alt="yteam" />
                            <img src={icons.flagArg} className='w-[21px] h-[14px]' alt="" />
                            <img src={icons.flagEsp} className='w-[21px] h-[14px]' alt="" />
                        </div>
                        <p className='DMSans-Medium text-white text-[10px] xl:text-xs pt-2'>{language.maga.p1}</p>
                        <p className='DMSans-Medium text-white text-[10px] xl:text-xs '>{language.maga.p2}</p>
                        <p
                            onClick={() => {
                                window.open("https://www.instagram.com/leeleesfood/")
                            }}
                            className='DMSans-Regular text-black text-lg cursor-pointer bg-white rounded-lg inline-block px-2 mt-4'>@leeleesfood</p>
                    </div>
                    <div className='mx-0 xl:mx-5 pt-4 xl:pt-0'>
                        <img src={images.galo} className="w-[270px] xl:w-[230px] xl:h-[230px] 2xl:w-[288px] 2xl:h-[288px] rounded-full" alt="maga" />
                        <p className='DMSans-Medium text-white text-[40px] xl:text-[48px] 2xl:text-[56px] '>Galo</p>
                        <p className='DMSans-Medium text-white xl:text-[18px] 2xl:text-[21px]'>{language.galo.role}
                        </p>
                        <div className='flex items-center gap-2'>
                            <img src={icons.starTeam} className="w-[18px] mt-1" alt="yteam" />
                            <img src={icons.flagArg} className='w-[21px] h-[14px]' alt="" />
                        </div>
                        <p className='DMSans-Medium text-white text-[10px] xl:text-xs pt-2'>{language.galo.p1} </p>
                        <p className='DMSans-Medium text-white text-[10px] xl:text-xs '>{language.galo.p2}</p>
                        <p
                            onClick={() => {
                                window.open("https://www.instagram.com/galodelger/")
                            }}
                            className='DMSans-Regular text-black text-lg cursor-pointer bg-white rounded-lg inline-block px-2 mt-4'>@galodelger</p>
                    </div>
                    <div className=' pt-4 xl:pt-0 '>
                        <img src={images.peter} className="w-[270px] xl:w-[230px] xl:h-[230px] 2xl:w-[288px] 2xl:h-[288px] rounded-full object-cover" alt="maga" />
                        <p className='DMSans-Medium text-white text-[40px] xl:text-[48px] 2xl:text-[56px] '>Peter</p>
                        <p className='DMSans-Medium text-white xl:text-[18px] 2xl:text-[21px]'>{language.peter.role}</p>
                        <div className='flex items-center gap-2'>
                            <img src={icons.starTeam} className="w-[18px] mt-1" alt="yteam" />
                            <img src={icons.flagEsp} className='w-[21px] h-[14px]' alt="" />
                        </div>
                        <p className='DMSans-Medium text-white text-[10px] xl:text-xs pt-2'>{language.peter.p1}</p>
                        <p className='DMSans-Medium text-white text-[10px] xl:text-xs '>{language.peter.p2} </p>
                        <p
                            onClick={() => {
                                window.open("https://www.instagram.com/juanpedroargento/")
                            }}
                            className='DMSans-Regular text-black text-lg cursor-pointer bg-white rounded-lg inline-block px-2 mt-4'>@juanpedroargento</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
