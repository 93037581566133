import React from "react";
import { InputText } from "../../components/Inputs/InputText";

export const TemplateForm = () => {
	return (
		<div>
			TemplateForm
			<InputText />
		</div>
	);
};
