import React from 'react'
import { icons } from '../../assets/icons';
import { useSelector } from 'react-redux';
import { logos } from '../../assets/logos';

export const ModalProyect = ({ onOpenProyect, infoPrject }) => {

    const { language } = useSelector(state => state.menu)

    const copyToClipboard = () => {
        const link = window.location.href;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(link)
                .then(() => {
                    alert(`Enlace copiado al portapapeles:${link}`);
                })
                .catch((error) => {
                    alert("Error al copiar el enlace al portapapeles: ", error);
                });
        } else {
            const tempInput = document.createElement("input");
            tempInput.value = link;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            alert(`Enlace copiado al portapapeles:${link}`);
        }
    };

    return (
        <div className='z-[200] fixed top-0 w-full h-full bg-white '>
            <div className='absolute top-0 z-50 w-full  h-[80px] xl:h-[110px]'>
                <div className="w-full h-full flex justify-between items-center">
                    <div className="pl-[53px]">
                        <img src={logos.logoHeader} className='w-[76px] h-[52px] xl:w-[125px] xl:h-[84px] object-contain' alt="" />
                    </div>
                    <div className="pr-[34px] md:pr-[53px] flex items-center">
                        <div
                            onClick={() => onOpenProyect(false)}
                            className="cursor-pointer">
                            <img src={icons.closeModalProyect} className="w-[50px] h-[50px] xl:w-[63px] xl:h-[63px] object-contain" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute top-0 bg-green-400 w-full h-full'>
                <div className='w-full h-8/12 md:h-8/12 lg:h-9/12 xl:h-9/12 bg-gray-400'>
                    <video className='w-full h-full object-contain bg-black' controls playsInline preload="metadata">
                        <source src={`${infoPrject.video}#t=0.5`} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                </div>
                <div className='w-full h-4/12 md:h-4/12 lg:h-3/12 xl:h-3/12 bg-[#D2C5F9] flex flex-col lg:flex-row  pt-6 lg:pt-8'>
                    <div className='w-full lg:w-10/12 flex flex-col items-start px-4 lg:pl-16'>
                        <p className='text-[#1E1E1D] text-[40px] lg:text-[68px] leading-[40px] lg:leading-[68px] DMSans-Medium  pb-4 lg:pb-0'>{infoPrject.title}</p>
                        <div className='lg:mt-2 border border-[#1E1E1D] rounded-xl  lg:h-12 px-5 flex justify-center items-center '>
                            <p className='text-[#1E1E1D] text-[20px] xl:text-[26px] leading-[26px] DMSans-Medium'>{infoPrject.subtitle}</p>
                        </div>
                    </div>
                    <div
                        className='w-full lg:w-2/12 pt-[60px] lg:pt-0 flex justify-center lg:justify-end items-center pr-0 lg:pr-11'>
                        <div
                            onClick={() => copyToClipboard()}
                            className='w-[153px] h-[50px] bg-[#DDF1C7] rounded-xl flex justify-center items-center'>
                            <img src={icons.share} className="w-[19px] h-[21px] object-contain" alt="" />
                            <p className='text-[#1E1E1D] text-[16px] DMSans-Bold pl-3'>{language.share}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
