import React from 'react'
import { icons } from '../../assets/icons'
import { AnimationImg } from './AnimationImg'
import { useSelector } from 'react-redux'
export const HomeScreen = ({ moveToproyects, changeLoading }) => {
    const { language } = useSelector(state => state.menu)
    return (
        <div className=" z-10 w-full h-100-80 xl:h-100-110 min-h-auto xl:min-h-[680px] ">
            <div className='w-full flex flex-col h-full'>
                <div className="w-full  h-10/12  ">
                    <div className="w-full h-full flex flex-col lg:flex-row items-center ">
                        <div className=" w-full lg:w-6/12 z-20 h-4/12 lg:h-full  flex flex-col items-start md:items-center lg:items-start">
                            <div className="w-full h-full lg:h-5/6  flex flex-col justify-center items-start md:items-center lg:items-start pl-[33px] lg:pl-[50px]">
                                <div className='pr-4 md:pr-0 pt-11 lg:pt-1'>
                                    <img src={language.imgTitle}
                                        className={`${language.is === 'english' ? "w-[280px] lg:w-[540px]" : "w-[320px] lg:w-[665px]"}`} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-8/12 md:w-6/12 lg:w-6/12 z-10 h-8/12 lg:h-full">
                            <div className='h-full '>
                                <AnimationImg changeLoading={changeLoading} />
                            </div>
                            {/* <div className='h-3/12 flex lg:hidden justify-center items-center '>
                            <button className='bg-[#FF521D] w-[154px] h-[50px] rounded-xl text-base text-white DMSans-Bold'>
                            Ver proyectos
                            </button>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className=" w-full h-2/12 items-end  flex z-20">
                    <div className="hidden lg:flex w-2/6 pl-11 pb-10  items-end ">
                        <img src={icons.mapIcon} className="h-[38px]" alt="icon map" />
                        <div className="px-6 h-[38px] ">
                            <p className="text-start text-white DMSans-Regular h-1/2 leading-none">LOCATION</p>
                            <p className="text-start text-white DMSans-Regular h-1/2 leading-none py-1">BUENOS AIRES, ARG.</p>
                        </div>
                        {/* <img src={icons.flagArg} className="w-6 h-4 object-cover" alt="flag " />
                        <img src={icons.flagMex} className="mx-[11px] w-6 h-4 object-cover" alt="flag " />
                        <img src={icons.flagEsp} className="w-6 h-4 object-cover" alt="flag " /> */}
                    </div>
                    <div className='w-2/6 hidden lg:flex justify-center items-center  pb-[34px]'>
                        <button onClick={() => moveToproyects()} className='bg-[#FF521D] w-[154px] h-[50px] rounded-xl text-base text-white DMSans-Bold'>
                            {language.btnSeeproyects}
                        </button>
                    </div>
                    <div className='h-full hidden w-2/6 lg:flex justify-center items-start '>
                    </div>
                    <div className='w-full h-full flex lg:hidden justify-center items-start transform -translate-y-7'>
                        <button onClick={() => moveToproyects()} className='bg-[#FF521D] w-[154px] h-[50px] rounded-xl text-base text-white DMSans-Bold'>
                            {language.btnSeeproyects}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}
