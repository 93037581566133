import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalProyect } from "./common/ModalProyect";
import { Contact } from "./home/Contact";
import { HomeScreen } from "./home/HomeScreen";
import { Projects } from "./home/Projects";
import { TheTeam } from "./home/TheTeam";
import { WeAre } from "./home/WeAre";
import { Menu } from "./Menu/Menu";
import { useRef } from 'react';
import { TemplateHeader } from "../pages"
import { icons } from "../assets/icons";
import { images } from "../assets/imgs";
import { changeToEnglis } from "../store/slices/menu/MenuSlice";
import { useEffect } from "react";
// ejemplo de uso de store con toolkit
// const height = window.innerHeight;
export const Home = ({ isEnglish = false }) => {
    const { isActive } = useSelector(state => state.menu)
    const dispatch = useDispatch()
    useEffect(() => {
        if (isEnglish) {
            dispatch(changeToEnglis())
        }
        // eslint-disable-next-line
    }, [])

    const [modalProyect, setModalProyect] = useState(false)
    const [infoPrject, setInfoPrject] = useState({ title: "", video: "" })
    const [percentage, setPercentage] = useState(0)
    const weAre = useRef(null);
    const proyects = useRef(null);
    const theTeam = useRef(null);
    const contact = useRef(null);
    const moveToweAre = () => {
        weAre.current.scrollIntoView({ behavior: "smooth" });
    };
    const moveToproyects = () => {
        proyects.current.scrollIntoView({ behavior: "smooth" });
    };
    const moveTotheTeam = () => {
        theTeam.current.scrollIntoView({ behavior: "smooth" });
    };
    const moveTocontact = () => {
        contact.current.scrollIntoView({ behavior: "smooth" });
    };

    const changeLoading = (percent) => {
        setPercentage(percent)
    }

    return (
        <>
            {/* 181817 */}
            {
                percentage <= 95 && <div className={`fixed animate__animated  ${percentage <= 60 ? '' : 'animate__fadeOut'} top-0 w-full h-full z-[5000] bg-[#181817] flex justify-center items-center`}>
                    <div className="absolute z-20 w-[125px] h-[84px]">
                        <img src={images.loadingLogo} className="w-full h-full" alt="" />
                    </div>
                    <div className="absolute z-10 w-[117px] h-[84px] flex items-end bg-white">
                        <div className="bg-[#FF521D] w-full" style={{ height: `${percentage}%` }}></div>
                    </div>
                </div>
            }
            <div className="hidden sm:block z-[100] w-[160px] h-[86px] fixed right-8 bottom-[28px] ">
                <img src={icons.iconScroll} className="object-contain w-full h-full" alt="" />
            </div>
            <TemplateHeader moveTocontact={moveTocontact}></TemplateHeader>
            {/* <div className="w-full h-100-80 xl:h-100-110  overflow-y-scroll"> */}
            {
                modalProyect && <ModalProyect onOpenProyect={setModalProyect} infoPrject={infoPrject}></ModalProyect>
            }
            {
                isActive &&
                <Menu
                    moveToweAre={moveToweAre}
                    moveToproyects={moveToproyects}
                    moveTotheTeam={moveTotheTeam}
                ></Menu>
            }
            <div>
                <HomeScreen moveToproyects={moveToproyects} changeLoading={changeLoading}></HomeScreen>
            </div>
            <div ref={weAre}>
                <WeAre moveToproyects={moveToproyects}></WeAre>
            </div>
            <div ref={proyects}>
                <Projects onOpenProyect={setModalProyect} setInfoPrject={setInfoPrject}></Projects>
            </div>
            <div ref={theTeam}>
                <TheTeam></TheTeam>
            </div>
            <div ref={contact}>
                <Contact></Contact>
            </div>
            {/* </div> */}
        </>
    );
};
