import React from "react";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate()
    return <div className="fixed w-full h-full flex justify-center items-center flex-col">
        <p className="text-white text-6xl font-bold py-16">Page not Found</p>
        <button onClick={() => {
            navigate("/")
        }} className='bg-[#FF521D] w-[154px] h-[50px] rounded-xl text-base text-white DMSans-Bold'>
            Back to home
        </button>
    </div>;
};
