import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { images } from "../../assets/imgs";
import { isDevice } from '../../utils/isMobile'
gsap.registerPlugin(ScrollTrigger);

const widthScreen = window.innerWidth;
const height = window.innerHeight;
export const AnimationImg = ({ changeLoading }) => {

    const canvasRef = useRef(null);
    const ref = useRef(null)
    useEffect(() => {
        function getObjectFitSize(
            contains /* true = contain, false = cover */,
            containerWidth,
            containerHeight,
            width,
            height
        ) {
            var doRatio = width / height;
            var cRatio = containerWidth / containerHeight;
            var targetWidth = 0;
            var targetHeight = 0;
            var test = contains ? doRatio > cRatio : doRatio < cRatio;

            if (test) {
                targetWidth = containerWidth;
                targetHeight = targetWidth / doRatio;
            } else {
                targetHeight = containerHeight;
                targetWidth = targetHeight * doRatio;
            }

            return {
                width: targetWidth,
                height: targetHeight,
                x: (containerWidth - targetWidth) / 2,
                y: (containerHeight - targetHeight) / 2
            };
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const dpr = window.devicePixelRatio || 1;
        context.imageSmoothingQuality = "high"
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        // context.mozImageSmoothingEnabled = false;
        // context.webkitImageSmoothingEnabled = false;
        // context.imageSmoothingEnabled = false;

        canvas.width = ref.current.clientWidth;
        canvas.height = ref.current.clientHeight;
        // console.log(ref.current.clientHeight)
        const frameCount = 139;
        const currentFrame = (index) =>
            `/imgAnimation/${(index + 1)
                .toString()
                .padStart(4, "0")}.jpg`;

        const images = [];
        const airpods = {
            frame: 0,
        };

        let imagesLoaded = 0;
        for (let i = 0; i < frameCount; i++) {
            const img = new Image();
            img.src = currentFrame(i);
            img.addEventListener('load', checkloader);
            images.push(img);
        }
        function checkloader() {
            imagesLoaded++;
            changeLoading((imagesLoaded * 100) / 139)
            if (imagesLoaded === frameCount) {
                startAnimation();
            }
        }

        function startAnimation() {
            gsap.to(airpods, {
                frame: frameCount - 1,
                snap: "frame",
                ease: "none",
                onUpdate: render,
                scrollTrigger: {
                    trigger: canvas,
                    // markers: true,
                    end: widthScreen > height ? '50%' : '80%',
                    start: widthScreen > height ? '-10%' : '-60%',
                    scrub: 0.5,
                },
                onComplete: () => {
                    canvas.style.willChange = 'auto';
                }
            });


            gsap.to(airpods, {
                duration: 2,
                y: 139,
                onUpdate: function () {
                    let valorFinal = 1;
                    let actualValue = Math.round(this.targets()[0].y - valorFinal);
                    let valueCount = 139 - actualValue;
                    canvas.style.willChange = 'transform';
                    let dimensions = getObjectFitSize(
                        true,
                        canvas.clientWidth,
                        canvas.clientHeight,
                        canvas.width,
                        canvas.height
                    );
                    context.clearRect(0, 0, dimensions.width, dimensions.height);
                    canvas.width = dimensions.width * dpr;
                    canvas.height = dimensions.height * dpr;
                    let ratio = Math.min(
                        canvas.clientWidth / dimensions.width,
                        canvas.clientHeight / dimensions.height
                    );
                    context.scale(ratio * dpr, ratio * dpr)
                    context.msImageSmoothingEnabled = false;
                    let imageRatio = images[valueCount].width / images[valueCount].height;
                    let scale = ratio > imageRatio ?
                        dimensions.width / images[valueCount].width :
                        dimensions.height / images[valueCount].height;
                    scale *= isDevice() ? 1.2 : 1.25;
                    let width = images[valueCount].width * scale;
                    let height = images[valueCount].height * scale;
                    let x = (dimensions.width - width) / 2;
                    let y = (dimensions.height - height) / 2;

                    context.drawImage(images[valueCount], x, y, width, height);
                },
                onComplete: () => {
                    canvas.style.willChange = 'auto';
                }
            });
        }

        function render() {
            canvas.style.willChange = 'transform';

            let dimensions = getObjectFitSize(
                true,
                canvas.clientWidth,
                canvas.clientHeight,
                canvas.width,
                canvas.height
            );

            context.clearRect(0, 0, dimensions.width, dimensions.height);
            canvas.width = dimensions.width * dpr;
            canvas.height = dimensions.height * dpr;
            let ratio = Math.min(
                canvas.clientWidth / dimensions.width,
                canvas.clientHeight / dimensions.height
            );

            context.scale(ratio * dpr, ratio * dpr)
            context.msImageSmoothingEnabled = false;
            let imageRatio = images[airpods.frame].width / images[airpods.frame].height;
            let scale = ratio > imageRatio ?
                dimensions.width / images[airpods.frame].width :
                dimensions.height / images[airpods.frame].height;
            scale *= isDevice() ? 1.2 : 1.25;
            let width = images[airpods.frame].width * scale;
            let height = images[airpods.frame].height * scale;
            let x = (dimensions.width - width) / 2;
            let y = (dimensions.height - height) / 2;

            context.drawImage(images[airpods.frame], x, y, width, height);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='w-full h-full anim_me relative ' ref={ref}>
                {/* <img src={images.imgFinalAnimation} className='absolute  w-full h-full object-cover z-10' alt="" /> */}
                <canvas className="absolute z-20  object-contain w-full h-full" id="hero-lightpass" ref={canvasRef} />
            </div>
        </>
    )
}
