import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "../../assets/icons";
import { logos } from "../../assets/logos";
import { changeToEnglis, changeToSpanish, toggleActiveMenu } from "../../store/slices/menu/MenuSlice";

export const TemplateHeader = ({ moveTocontact }) => {
    const dispatch = useDispatch()
    const { language } = useSelector(state => state.menu)
    return (
        <div className="sticky  top-0 z-50 w-full h-[80px] xl:h-[110px] ">
            <div className="w-full h-full flex justify-between pt-[30px] px-[25px] xl:px-[53px]">
                <div className="w-2/6">
                    <img src={logos.logoHeader} className='w-[76px] h-[52px] xl:w-[125px] xl:h-[84px] object-contain' alt="" />
                </div>
                <div
                    onClick={() => {
                        if (language.is === "spanish") {
                            dispatch(changeToEnglis())
                        }
                        else {
                            dispatch(changeToSpanish())
                        }
                    }}
                    className="w-2/6 text-center flex justify-center cursor-pointer">
                    <p className={`text-white text-xs xl:text-base underline-offset-8 ${language.is === "spanish" ? 'underline' : ''}`}>ESP</p>
                    <p className="text-white text-xs xl:text-base px-1">/</p>
                    <p className={`text-white text-xs xl:text-base underline-offset-8 ${language.is === "english" ? 'underline' : ''}`}>ENG</p>
                </div>
                <div className="w-2/6 flex justify-end">
                    <p
                        onClick={() => moveTocontact()}
                        className="hidden xl:flex text-white text-[17.75px] font-medium pr-[48px] cursor-pointer pt-[10px]">{language.contact}</p>
                    <div
                        onClick={() => dispatch(toggleActiveMenu(true))}
                        className="cursor-pointer">
                        <img src={icons.iconMenu} className="w-[35px] h-[26px] xl:w-[35px] xl:h-[48px] object-contain" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};
