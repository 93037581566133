import { createBrowserRouter } from "react-router-dom";
import { NotFound } from "../pages/index";
import { routePrivate } from "./privateRouter";
import { routerPublic } from "./publicRouter";

export const router = createBrowserRouter([
	{
		errorElement: <NotFound />,
		children: [routerPublic, routePrivate],
	},
]);
