import React from 'react'
import { icons } from '../../assets/icons'
import { useSelector } from 'react-redux'

export const WeAre = ({ moveToproyects }) => {
    const { language } = useSelector(state => state.menu)
    return (
        <div className="w-full bg-[#D2C5F9] flex flex-col h-auto xl:h-screen min-h-auto xl:min-h-[970px] ">
            <div className="w-full h-5/12 xl:h-5/12  2xl:h-6/12  flex justify-center items-end text-center">
                <div>
                    <div className='pt-9 xl:pt-0 pb-0 xl:pb-[130px] text-start xl:text-center'>
                        <span className='DMSans-Medium text-[#1E1E1D] text-sm xl:text-xl uppercase'>{language.introTextWeAre}</span>
                    </div>
                    <div className='xl:pt-5 2xl:pt-10 hidden xl:block'>
                        <span className='leading-[53px] 2xl:leading-[63px] DMSans-Medium text-[#1E1E1D] text-[48px] 2xl:text-[58px]'>{language.titleWeAreDesktop.p1} </span>
                        <span className='leading-[53px] 2xl:leading-[63px] DMSans-Medium text-[#FF521D] text-[48px] 2xl:text-[58px] '>{language.titleWeAreDesktop.p2}</span>
                        <p className='leading-[53px] 2xl:leading-[63px] DMSans-Medium text-[#1E1E1D] text-[48px] 2xl:text-[58px]'>{language.titleWeAreDesktop.p3} </p>
                        <p className='leading-[53px] 2xl:leading-[63px] DMSans-Medium text-[#1E1E1D] text-[48px] 2xl:text-[58px]'>{language.titleWeAreDesktop.p4} </p>
                    </div>
                    {language.is === "spanish"
                        ?
                        <div className='pt-[70px] block xl:hidden text-start'>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>Trabajamos</p>
                            <span className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px] '>en la</span>
                            <span className='leading-[43px] DMSans-Medium text-[#FF521D] text-[42px] '> creatividad </span>
                            <p className='leading-[43px] DMSans-Medium text-[#FF521D] text-[42px] '> y ejecución </p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>de proyectos </p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>produciendo </p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>eventos y </p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>experiencias. </p>
                        </div>
                        :
                        <div className='pt-[70px] block xl:hidden text-start'>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>We work on</p>
                            <p className='leading-[43px] DMSans-Medium text-[#FF521D] text-[42px] '> creativity and </p>
                            <span className='leading-[43px] DMSans-Medium text-[#FF521D] text-[42px] '> execution </span>
                            <span className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px] '> of</span>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'> projects,</p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>producing events </p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>and experiences </p>
                            <p className='leading-[43px] DMSans-Medium text-[#1E1E1D] text-[42px]'>for brands. </p>
                        </div>
                    }
                </div>
            </div>
            <div className="w-full h-full xl:h-5/12 2xl:h-4/12 flex flex-col  xl:flex-row pt-[60px] xl:pt-[60px] 2xl:pt-[105px] pl-9 xl:pl-0 ">
                <div className="w-full xl:w-1/2 h-full flex flex-col items-start md:items-center xl:items-start xl:flex-row justify-center  ">
                    <div> <img src={icons.startTwo} className="w-[120px] h-[120px] xl:w-[154px] xl:h-[154px] " alt="shape 1" /></div>
                    <div className='pl-0 xl:pl-8 pt-4 xl:pt-0'>
                        <p className='text-lg xl:text-2xl text-[#1E1E1D] uppercase DMSans-Medium flex'> <img src={icons.starThree} className="pr-4" alt="shape3" />{language.ourPropose}</p>
                        {
                            language.is === "spanish"
                                ?
                                <>
                                    <p className='hidden xl:block text-[15px] xl:text-lg text-start DMSans-Medium'>
                                        Aportar al posicionamiento de las marcas con las <br />
                                        que colaboramos, curando proyectos que refuerzan <br />
                                        el vínculo que tienen con sus comunidades y <br />
                                        consumidores.<strong className='DMSans-Bold'> Acercamos data, compartimos data <br />
                                            y creamos data para humanizar marcas. </strong> </p>
                                    <p className='block xl:hidden text-[15px] xl:text-lg text-start DMSans-Medium pt-2'>
                                        Aportar al posicionamiento de las marcas <br /> con las
                                        que colaboramos, curando proyectos <br /> que refuerzan
                                        el vínculo que tienen con sus <br /> comunidades y
                                        consumidores. <strong className='DMSans-Bold'> Acercamos <br /> data, compartimos data
                                            y creamos data <br /> para humanizar marcas.</strong> </p></>
                                :
                                <>
                                    <p className='text-[15px] xl:text-lg text-start DMSans-Medium'>
                                        Contributing to the positioning of the brands we <br />
                                        collaborate with by curating projects that <br />
                                        reinforce the bond they have with their <br />
                                        communities and consumers. <strong className='DMSans-Bold'>We share data <br />
                                            and we create data in order to humanize brands.</strong> </p>
                                </>
                        }
                    </div>
                </div>
                <div className="w-full xl:w-1/2 h-full flex flex-col items-start md:items-center xl:items-start xl:flex-row justify-center  pt-9 xl:pt-0">
                    <div> <img src={icons.rectangle} className="w-[120px] h-[120px] xl:w-[154px] xl:h-[154px]" alt="shape 2" /></div>
                    <div className='pl-0 xl:pl-8 pt-4 xl:pt-0'>
                        <p className='text-lg xl:text-2xl text-[#1E1E1D] uppercase DMSans-Medium flex'> <img src={icons.starThree} className="pr-4" alt="shape3" />{language.wehaveData}</p>
                        <p className='text-[15px] xl:text-lg text-start DMSans-Medium pt-2 xl:pt-0'>
                            · {language.posintsWeHave.p1}  <br />
                            · {language.posintsWeHave.p2}  <br />
                            · {language.posintsWeHave.p3}  <br />
                            · {language.posintsWeHave.p4} <br />
                            · {language.posintsWeHave.p5} <br />
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-full h-3/12 xl:h-2/12 2xl:h-2/12 flex justify-center items-end pb-9 pt-16 xl:pt-0">
                <button
                    onClick={() => moveToproyects()}
                    className="w-[162px] xl:w-[200px] h-[51px] bg-[#DDF1C7] xl:bg-[#1E1E1D] rounded-[13px] xl:rounded-[13px] flex justify-center items-center">
                    <p className='DMSans-Bold text-black xl:text-white text-base xl:text-lg pr-0 xl:pr-3'>{language.ourCases}</p>
                    <img src={icons.arrowDown} className="w-[18px] h-6 object-contain hidden xl:block" alt="arrow down" />
                </button>
            </div>
        </div>
    )
}
