import iconMenu from "./MenuIcon.svg";
import iconMenuBlack from "./menuiconBlack.svg";
import startOne from "./star1.svg";
import startTwo from "./star2.svg";
import mapIcon from "./mapIcon.svg";
import flagMex from "./flagMex.png";
import flagEsp from "./flagEsp.png";
import flagArg from "./flagArg.png";
import rectangle from "./rectangle.svg";
import starThree from "./star3.svg";
import arrowDown from "./arrowdowns.svg";
import arrowROrange from "./arrowRightOrange.svg";
import cardNext from "./cardnext.svg";
import arrowAngleUp from "./arrowangleup.svg";
import arrowAngleUpBlack from "./arrowangleupblack.svg";
import starTeam from "./startheam.svg";
import close from "./close.svg";
import arrowMenu from "./arrowMenu.svg";
import share from "./share.svg";
import closeModalProyect from "./closeModalProyc.svg";
import iconScroll from "./Scrollv2.svg";

export const icons = {
	iconMenu,
	iconScroll,
	closeModalProyect,
	close,
	share,
	arrowMenu,
	arrowROrange,
	iconMenuBlack,
	startOne,
	arrowDown,
	startTwo,
	mapIcon,
	rectangle,
	flagMex,
	arrowAngleUp,
	arrowAngleUpBlack,
	starThree,
	cardNext,
	flagEsp,
	flagArg,
	starTeam,
};
