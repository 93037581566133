const mobile = {
	Android: function () {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function () {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function () {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function () {
		return mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows();
	},
};

export function isDevice() {
	if (mobile.any()) {
		return true;
	}
	return false;
}

export const getUA = () => {
	let device = "Unknown";
	const ua = {
		"Generic Linux": /Linux/i,
		Android: /Android/i,
		BlackBerry: /BlackBerry/i,
		Bluebird: /EF500/i,
		"Chrome OS": /CrOS/i,
		Datalogic: /DL-AXIS/i,
		Honeywell: /CT50/i,
		iPad: /iPad/i,
		iPhone: /iPhone/i,
		iPod: /iPod/i,
		macOS: /Macintosh/i,
		Windows: /IEMobile|Windows/i,
		Zebra: /TC70|TC55/i,
	};
	Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
	return device;
};
