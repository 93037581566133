import { LayoutAuth } from "../layout";
import { TemplateForm, TemplateHeader } from "../pages";

export const pathPublic = {
	auth: "auth",
	reset: "reset",
};

export const routerPublic = {
	element: <LayoutAuth />,
	path: pathPublic.auth,
	children: [
		{
			index: true,
			element: <TemplateForm />,
		},
		{
			path: pathPublic.reset,
			element: <TemplateHeader />,
		},
	],
};
