import "./App.css";
import "animate.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
// import WebVersion from "./components/WebVersions";
import "../src/assets/fonts/DMSans-Bold.ttf";
import "../src/assets/fonts/DMSans-Medium.ttf";
import "../src/assets/fonts/DMSans-Regular.ttf";

function App() {
	return (
		<Provider store={store}>
			{/* <WebVersion></WebVersion> */}
			<RouterProvider router={router} />
		</Provider>
	);
}

export default App;
