import React from 'react'
import { icons } from '../../assets/icons'
import { CarruselProjects } from '../../components/CarruselProjects'
import { useSelector } from 'react-redux'

export const Projects = ({ onOpenProyect, setInfoPrject }) => {
    const { language } = useSelector(state => state.menu)
    return (
        <div className='w-full h-auto xl:h-screen min-h-auto xl:min-h-[970px]  bg-[#1E1E1D] flex flex-col xl:flex-row justify-center items-center pt-[117px] xl:pt-0'>
            <div className='h-full w-full xl:w-4/12 flex flex-col justify-center items-start pl-8 xl:pl-[40px] 2xl:pl-[67px]'>
                <div className=''>
                    <p className='text-[#EEECEC] text-[50px] xl:text-[50px] 2xl:text-[76px] DMSans-Medium leading-[50px] xl:leading-[50px] 2xl:leading-[65px]'>PROJECTS</p>
                    <div className='flex'>
                        <img src={icons.arrowROrange} className="xl:w-[45px] xl:h-[45px]  2xl:w-[60px] 2xl:h-[60px]" alt="arrow orange" />
                        <p className='pl-2 text-[#EEECEC] text-[50px] xl:text-[50px] 2xl:text-[76px] DMSans-Medium leading-[50px] xl:leading-[50px] 2xl:leading-[65px]'>BRAND </p>
                    </div>
                    <span className='underline underline-offset-8  decoration-2 text-[#D0F3B4] text-[50px] xl:text-[50px] 2xl:text-[76px] DMSans-Medium leading-[50px] xl:leading-[50px] 2xl:leading-[65px]  decoration-[#FF521D]'>&</span>
                    <span className='underline underline-offset-8  decoration-2 text-[#EEECEC] text-[50px] xl:text-[50px] 2xl:text-[76px] DMSans-Medium leading-[50px] xl:leading-[50px] 2xl:leading-[65px]  decoration-[#FF521D]'>CONTENT</span>
                </div>
                <div className='pt-7 xl:pt-[57px]'>
                    <p className='DMSans-Regular text-xl text-[#EEECEC] underline decoration-1' >{language.ourProyects}</p>
                </div>
            </div>
            <div className='h-full w-full xl:w-8/12 pt-10 xl:pt-0 pl-8 pb-[112px] xl:pb-0'>
                <CarruselProjects onOpenProyect={onOpenProyect} setInfoPrject={setInfoPrject}></CarruselProjects>
            </div>
        </div>
    )
}
