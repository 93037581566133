// import { redirect } from "react-router-dom";
// import { getDataExample } from "../api";
import { LayoutHome } from "../layout";
import { Home } from "../pages";

export const pathPrivate = {
	root: "/",
};

// const getRequestPrivateRoute = async (data) => {
// 	console.log(localStorage.getItem("token"));
// 	const { status, payload } = await getDataExample();
// 	if (status > 0) {
// 		return redirect("auth");
// 	}
// 	return payload;
// };

export const routePrivate = {
	path: pathPrivate.root,
	element: <LayoutHome />,
	// loader: getRequestPrivateRoute,
	children: [
		{
			index: "/",
			element: <Home />,
		},
		{
			path: "/eng",
			element: <Home isEnglish={true} />,
		},
	],
};
