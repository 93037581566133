import AperolLiveVIDEOconverted from "./1.AperolLive_VIDEO-converted.mp4";
import BloombergNYVIDEO from "./2.Bloomberg NY_VIDEO.mp4";
import SkyBreshVIDEOconverted from "./3.SkyBresh_VIDEO-converted.mp4";
import Lali_SonyMusicVIDEOconverted from "./4.Lali_SonyMusic_VIDEO-converted.mp4";
import AndesOrigenFresquitaVIDEOconverted from "./5.AndesOrigen_Fresquita_VIDEO-converted.mp4";
import FeriaOilGasGrupoTechintconverted from "./6.FeriaOilGasGrupoTechintconverted.mp4";
import AperolSpritzDistrictVIDEOconverted from "./7.AperolSpritz_District_VIDEO-converted.mp4";
import HexactaSessionsVIDEOconverted from "./8.HexactaSessions_VIDEO-converted.mp4";
import HeinekenBarVIDEOconverted from "./9_HeinekenBar_VIDEO-converted.mp4";
import aperolprimaveravideo from "./APEROLPRIMAVERAVIDEO.mp4";
import revolvermp4 from "./REVOLVERVIDEO.mp4";
import negronivideo from "./NEGRONI VIDEO.mp4";
import marbellavideo from "./JD Marbella VIDEO.mp4";

export const video = {
	AperolLiveVIDEOconverted,
	BloombergNYVIDEO,
	SkyBreshVIDEOconverted,
	Lali_SonyMusicVIDEOconverted,
	AndesOrigenFresquitaVIDEOconverted,
	FeriaOilGasGrupoTechintconverted,
	AperolSpritzDistrictVIDEOconverted,
	HexactaSessionsVIDEOconverted,
	HeinekenBarVIDEOconverted,
	aperolprimaveravideo,
	revolvermp4,
	negronivideo,
	marbellavideo,
};
