import React from "react";
import { Outlet } from "react-router-dom";
// import { TemplateHeader } from "../pages";

export const LayoutHome = () => {
    return (
        <div className="">
            {/* <TemplateHeader></TemplateHeader> */}
            <Outlet />
        </div>
    );
};
