import React, { useState } from 'react'
import { icons } from '../../assets/icons'
import { images } from '../../assets/imgs'
import { useSelector } from 'react-redux'

export const Contact = () => {
    const { language } = useSelector(state => state.menu)
    const [name, setName] = useState("")
    const [email, setemail] = useState("")
    const [tel, settel] = useState("")
    const [message, setMessage] = useState("")

    const onresetForm = () => {
        setTimeout(function () {
            alert("Mensaje enviado")
            // console.log("Han pasado 2 segundos");
            setName("")
            setemail("")
            settel("")
            setMessage("")
        }, 1000);
    }
    return (
        <div className='w-full h-auto lg:h-screen min-h-auto xl:min-h-[970px]  bg-D2C5F9 '>
            <iframe title='algo' id="ifra" name='ifra' frameBorder="0" style={{ display: 'none' }}></iframe>
            <form className='w-full lg:h-8/12 2xl:h-9/12'
                method="POST" target="ifra" action="https://formspree.io/f/mknaylnv"
            >
                <div className='pt-[120px] lg:pt-[220px] pl-[31px] lg:pl-16 w-full h-full text-start md:text-center lg:text-start'>
                    <p className='text-[#1E1E1D] text-[40px] lg:text-[60px] 2xl:text-[76px] DMSans-Medium h-4/12'>{language.cantactTitle}</p>
                    <div className='pt-10  flex flex-col  lg:flex-row w-full h-8/12 lg:pt-6 2xl:pt-8'>
                        <div className='w-full lg:w-1/2 h-full '>
                            <p className='DMSans-Medium text-[#1E1E1D] text-[21px] lg:text-[26px]'>{language.nameAnd}</p>
                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name="Nombre"
                                className='w-[321px] lg:w-[450px] 2xl:w-[610px] bg-transparent border-b border-black text-[#1E1E1D] text-[20px] focus:outline-0'
                                type="text" />
                            <p className='DMSans-Medium text-[#1E1E1D] text-[21px] lg:text-[26px] pt-6 lg:pt-6 2xl:pt-8'>{language.mail}</p>
                            <input
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                name="Email"
                                className='w-[321px] lg:w-[450px] 2xl:w-[610px] bg-transparent border-b border-black text-[#1E1E1D] text-[20px] focus:outline-0'
                                type="text" />
                            <p className='DMSans-Medium text-[#1E1E1D] text-[21px] lg:text-[26px] pt-6 lg:pt-6 2xl:pt-8'>{language.phone}</p>
                            <input
                                value={tel}
                                onChange={(e) => settel(e.target.value)}
                                name="Phone"
                                className='w-[321px] lg:w-[450px] 2xl:w-[610px] bg-transparent border-b border-black text-[#1E1E1D] text-[20px] focus:outline-0 ' type="number" />
                        </div>
                        <div className='w-full lg:w-1/2 h-full pt-[29px] lg:pt-0'>
                            <p className='DMSans-Medium text-[#1E1E1D] text-[21px] lg:text-[26px]'>{language.text}</p>
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                name="Text"
                                className="w-[321px] lg:w-[500px] h-[119px] lg:h-[172px] 2xl:w-[700px] 2xl:h-[172px] px-3 py-2 bg-transparent border border-black rounded-xl focus:outline-0"></textarea>
                            <div className="w-[321px] md:w-full lg:w-[500px] 2xl:w-[700px] flex justify-center lg:justify-end pt-[14px] lg:pt-0">
                                <button
                                    type="submit"
                                    onClick={() => onresetForm()}
                                    className=' bg-[#1e1e1D] w-[162px] lg:w-[262px] h-[50px] lg:h-[65px] rounded-xl lg:rounded-3xl text-[#D2C5F9] text-base xl:text-[26px]'>{language.send}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className=' pl-[31px] md:pl-16 pt-[96px] md:pt-10 lg:pt-0 w-full md:h-4/12 2xl:h-3/12  flex flex-col md:flex-row items-end '>
                <div className='w-full md:w-1/2'>
                    <div className='flex items-center'>
                        <p className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl pr-4'>Headquarters: </p>
                        <img src={icons.flagArg} className="w-6 h-4 object-cover" alt="flag " />
                    </div>
                    <p className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl pr-4'>Buenos Aires-Argentina</p>
                    <div className='flex items-center pt-9 pb-9'>
                        <a className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl underline' href='https://www.instagram.com/quieroladata/'>Instagram</a>
                        <p className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl px-1'>·</p>
                        <a className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl underline' href='https://www.linkedin.com/company/quieroladata/' >Linkedin</a>
                    </div>
                </div>
                <div className='w-full md:w-1/2 flex h-[250px] md:h-auto'>
                    <div className='w-1/2'>
                        <div className='flex items-center'>
                            <p className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl pr-4'>Offices:</p>
                            <img src={icons.flagEsp} className="w-6 h-4 object-cover" alt="flag " />
                            <img src={icons.flagMex} className="mx-[11px] w-6 h-4 object-cover" alt="flag " />
                        </div>
                        <p className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl pr-4'>Madrid, Spain. CDMX, México</p>
                        <div className='flex items-center pt-9 pb-9'>
                            <a className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl underline' href='/' >©2023 Quieroladata</a>
                        </div>
                    </div>
                    <div className='w-1/2 flex justify-end items-end pr-12'>
                        <img src={images.group165} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
