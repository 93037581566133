import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from '../../assets/icons'
import { toggleActiveMenu } from '../../store/slices/menu/MenuSlice'

export const Menu = ({ moveToweAre, moveToproyects, moveTotheTeam }) => {
    const dispatch = useDispatch()
    const [menuseleted, setMenuseleted] = useState(0)
    const { language } = useSelector(state => state.menu)

    return (
        <div className='fixed z-[100] top-0 w-full h-full bg-D2C5F9 animate__animated animate__fadeInRight animate__faster'>
            <div className='w-full h-8/12 lg:h-8/12 2xl:h-9/12 '>
                <div className='w-full h-[80px] lg:h-[110px] flex justify-between items-center '>
                    <div className="pl-[25px] xl:pl-[53px] pt-[30px]">
                        <img src={icons.iconMenuBlack} className='w-[76px] h-[52px] lg:w-[125px] lg:h-[84px] object-contain' alt="" />
                    </div>
                    <div className='lg:pt-10 pr-10 pt-[30px]'>
                        <div
                            onClick={() => dispatch(toggleActiveMenu(false))}
                            className='cursor-pointer'>
                            <img src={icons.close} className='w-8 h-8' alt="close" />
                        </div>
                    </div>
                </div>
                <div className=' w-full h-100-80percent lg:h-100-110percent flex flex-col items-start justify-center pl-[30px] lg:pl-[70px]'>
                    <div
                        onClick={() => {
                            dispatch(toggleActiveMenu(false))
                            moveToweAre()
                        }}
                        onMouseEnter={() => {
                            setMenuseleted(1)
                        }}
                        onMouseLeave={() => { setMenuseleted(0) }}
                        className=' cursor-pointer border-b-4 border-transparent hover:border-black flex items-center pt-4 lg:pt-8'>
                        <p className='text-[35px] lg:text-[62px] xl:text-[82px] leading-[35px] lg:leading-[62px] xl:leading-[82px] text-[#1E1E1D] pr-2'>{language.menuWedo}</p>
                        {
                            menuseleted === 1 &&
                            <img src={icons.arrowMenu} className="h-[26px] lg:h-[55px]" alt="arrow menu" />
                        }
                    </div>
                    <div
                        onClick={() => {
                            dispatch(toggleActiveMenu(false))
                            moveToproyects()
                        }}
                        onMouseEnter={() => {

                            setMenuseleted(2)
                        }}
                        onMouseLeave={() => { setMenuseleted(0) }}
                        className=' cursor-pointer border-b-4 border-transparent hover:border-black flex items-center pt-4 lg:pt-8'>
                        <p className='text-[35px] lg:text-[62px] xl:text-[82px] leading-[35px] lg:leading-[62px] xl:leading-[82px] text-[#1E1E1D] pr-2'>{language.menuBrandcases}</p>
                        {
                            menuseleted === 2 &&
                            <img src={icons.arrowMenu} className="h-[26px] lg:h-[55px]" alt="arrow menu" />
                        }
                    </div>
                    <div
                        onClick={() => {
                            dispatch(toggleActiveMenu(false))
                            moveTotheTeam()
                        }}
                        onMouseEnter={() => {
                            setMenuseleted(3)
                        }}
                        onMouseLeave={() => { setMenuseleted(0) }}
                        className=' cursor-pointer border-b-4 border-transparent hover:border-black flex items-center pt-4 lg:pt-8'>
                        <p className='text-[35px] lg:text-[62px] xl:text-[82px] leading-[35px] lg:leading-[62px] xl:leading-[82px] text-[#1E1E1D] pr-2'>{language.menuWho}</p>
                        {
                            menuseleted === 3 &&
                            <img src={icons.arrowMenu} className="h-[26px] lg:h-[55px]" alt="arrow menu" />
                        }
                    </div>
                </div>
            </div>
            <div className='pl-[30px] lg:pl-16 w-full h-4/12 lg:h-4/12 2xl:h-3/12  flex flex-col items-start justify-end '>
                <div className='w-full  flex flex-col lg:flex-row'>
                    <div className='flex flex-col justify-center w-full lg:w-[350px]'>
                        <div className='flex items-center'>
                            <div className='flex items-center'>
                                <p className='DMSans-Regular text-[#1E1E1D] md:text-xl 2xl:text-2xl pr-4'>Headquarters: </p>
                                <img src={icons.flagArg} className="w-6 h-4 object-cover" alt="flag " />
                            </div>
                        </div>
                        <p className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl pr-4'>Buenos Aires-Argentina</p>
                    </div>
                    <div>
                        <div className='pl-0 lg:pl-40 pt-8 lg:pt-0'>
                            <div className='flex items-center'>
                                <div className='flex items-center'>
                                    <p className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl pr-4'>Offices:</p>
                                    <img src={icons.flagEsp} className="w-6 h-4 object-cover" alt="flag " />
                                    <img src={icons.flagMex} className="mx-[11px] w-6 h-4 object-cover" alt="flag " />
                                </div>
                            </div>
                            <p className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl pr-4'>Madrid, Spain. CDMX, México</p>

                        </div>

                    </div>
                </div>
                <div className='w-full flex'>
                    <div className=''>
                        <div className='flex flex-col lg:flex-row '>
                            <div className='flex items-center pt-8 lg:pt-9 pb-0 lg:pb-9 w-full lg:w-[350px]'>
                                <a className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl underline' href='https://www.instagram.com/quieroladata/'>Instagram</a>
                                <p className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl px-1'>·</p>
                                <a className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl underline' href='https://www.linkedin.com/company/quieroladata/' >Linkedin</a>
                            </div>
                            <div className='flex items-center pl-0 lg:pl-40 pt-0 lg:pt-9 pb-9'>
                                <a className='DMSans-Regular text-[#1E1E1D] lg:text-xl 2xl:text-2xl underline' href='/' >©2023 Quieroladata</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
